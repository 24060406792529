import styled, { css } from 'styled-components'
import { IconProps, LoaderIcon } from 'components/atoms/Icons'
import { Row } from 'components/atoms/Row'

type Props = {
  centered?: boolean
} & Pick<IconProps, 'size' | 'className'>

export function Loader({ centered, size, className }: Props) {
  return (
    <LoaderWrapper centered={centered} data-testid="loader">
      <LoaderIcon size={size} className={className} />
    </LoaderWrapper>
  )
}

export const LoaderWrapper = styled(Row)<Pick<Props, 'centered'>>`
  ${({ centered }) =>
    centered &&
    css`
      place-content: center;
      margin-top: 64px;
    `}
`
